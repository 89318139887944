import { Component, OnInit ,EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Photo } from 'app/ClassFiles/photo';
import { MasterService } from 'app/services/master.service';

@Component({
  selector: 'app-addphotos',
  templateUrl: './addphotos.component.html',
  styleUrls: ['./addphotos.component.css']
})
export class AddphotosComponent implements OnInit {
  Addphotosform : FormGroup;
  fileToUpload: File = null;
  imageUrl: string;
  partner;
  submited = new EventEmitter<string>();
  userid: string;
  constructor(private fb: FormBuilder,private service: MasterService,private router: Router) {
    this.userid=localStorage.getItem('UserId')

    this.service.FillDropDown("PartnerProgram","distinct partnername","Partner.partnerid","inner join AcademicyearPartner on AcademicyearPartner.partnerid=PartnerProgram.userid  inner join PartnerProfile on PartnerProgram.userid=PartnerProfile.userid inner join partner on PartnerProfile.superpartnerid = partner.partnerid where academicyear in('2023-2024','2024-2025') and partnerprofile.userid='"+ this.userid +"'").subscribe((data: any) => {
      this.partner = data;
    });
   }

  ngOnInit() {
    this.Addphotosform = this.fb.group({
      partnername: ['', Validators.required],
      titlename : ['', Validators.required],
      filename: ['', Validators.required]
    });
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    //Show image preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  AddPhotos(){
    const photo: Photo = new Photo();
    photo.ptid = this.Addphotosform.value.partnername;
    photo.filename = this.Addphotosform.value.titlename;
    // console.log(photo.ptid,photo.filename,this.fileToUpload)
    this.service.AddPartnerPhotogallery(photo.ptid,photo.filename,this.userid,this.fileToUpload).subscribe((res) => {
      if (res == "Success") {        
        this.submited.emit(res.toString());
        localStorage.setItem('gid', res.toString());
        alert("Photo Added Succesfuly");
        this.router.navigate(['/PhotosList']);
      }
      else if(res == "Exist"){
        alert("Already Exists");
        this.router.navigate(['/AddPhotos']);
      }
      else{
        alert("Failed to Add Photo");
        this.router.navigate(['/AddPhotos']);
      }
    }); 

  }
}
