import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { OtppageComponent } from './otppage/otppage.component';
import { AuthGuard } from './services/auth.guard';
import { OtpauthGuard } from './services/otpauth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'Login', pathMatch: 'full'},
 
  {
    path: '', component: AdminLayoutComponent,
    children: [{ path: '', loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'}]
  },
  {
    path: 'Login', loadChildren: './login/login.module#LoginModule'
  },
  {
    path: 'OTPPage', component:OtppageComponent ,canActivate:[AuthGuard]
  },
 
  { path: '**', redirectTo: 'login' } 


];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
