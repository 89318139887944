import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MasterService } from 'app/services/master.service';
// import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-otppage',
  templateUrl: './otppage.component.html',
  styleUrls: ['./otppage.component.scss']
})
export class OtppageComponent implements OnInit {
  mobileno: string = '';
  otpform: FormGroup;
  showOtpSection = false;
  otpReceived: string;
  loading: boolean;
  enteredOtp: string;
  verificationMessage: string;
  isSuccess: boolean;

  username: string;
  lng: any;
  lat: any;
  ipAddress: any;
  address;
  dev;
  UserId: string = '';
  pgid: string;

  constructor(private fb: FormBuilder, private service: MasterService, private router: Router, private route: ActivatedRoute) {
    this.username = localStorage.getItem('UserName');
    this.UserId = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');

    // const isLoggedIn = localStorage.getItem('isLoggedIn');
    // if (!isLoggedIn) {
    //   this.router.navigate(['/Login']);
    //   return; 
    // }

    // if(!this.UserId){
    //   this.route.queryParams.subscribe(params => {
    //     this.dev = params.dev;
    //     const navigation = this.router.getCurrentNavigation();
    //     const state = navigation?.extras?.state as { dev: boolean };
    //     this.dev = state?.dev || false;

    //     if (!this.dev) {
    //       this.router.navigate(['/Login']);
    //     }
    //   });
    // }

    this.service.getPosition().then(pos => {
      this.lng = pos.lng;
      this.lat = pos.lat;
      this.getAddress(this.lat, this.lng);
    });

    this.service.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }

  ngOnInit() { }

  getAddress(lat, lng) {
    this.service.getAddress(lat, lng).subscribe((response: any) => {
      this.address = response.display_name;
    });
  }

  onRequestOtp(mobileno) {
    if (!mobileno) {
      alert('Mobile number is required');
      return;
    }
    this.loading = true;

    this.service.GetOTPPSahyogiLogin(mobileno, this.UserId).subscribe((res: string) => {
      
      if (res === 'Mobile number is invalid') {
        //(this.UserId === 'AD3' || this.UserId === 'AD4') && 
        alert('Mobile number is invalid');
      }
      else {
        this.otpReceived = res;
        this.showOtpSection = true;
        this.loading = false;
      }
    },
      error => {
        console.error('Error requesting OTP:', error);
        this.loading = false;
      });
  
}

  onVerifyOtp(enteredOtp) {
    if (enteredOtp === this.otpReceived) {
      alert('OTP verified successfully!');
      this.isSuccess = true;

      localStorage.setItem('isOtpVerify', 'true');

      const requestBody = {
        userid: this.UserId,
        mobile: this.mobileno,
        startaddress: this.address,
        startloclongitude: this.lng,
        endloclongitude: this.lat,
        userip: this.ipAddress
      };

      this.service.SavePartnerProfileActivity(this.UserId, this.mobileno, requestBody)
        .subscribe(

          response => {
            if (this.username == 'sapyouth' || this.username == 'saparibayouth' || this.username == 'flipkartfoundation' || this.username == 'p&g' || this.username == 'ltipune' || this.username == 'ibmskillbuild') {
              this.router.navigate(["/IDIDashboard"]);
            }
            if (this.username == 'superadmin' || this.username == 'superwns' || this.username == 'superdpworld') {
              this.router.navigate(["/AdminDashboard"]);
            }
            if (this.username == 'pif') {
              this.router.navigate(["/PIFDashboard"]);
            }
            if (this.pgid == 'P9') {
              this.router.navigate(["/MyeschoolDashboard"])
            }
            if (this.pgid == 'P1') {
              this.router.navigate(['/Dashboard']);
            }
          },
          error => {
            console.error('Error saving partner profile activity:', error);
          }
        );
    } else {
      // this.toastr.error('', 'Error');
      alert('Invalid OTP, Please try again!');
      this.isSuccess = false;
    }
  }
}