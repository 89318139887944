import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { FormControl } from '@angular/forms';
import * as XLSX from 'xlsx';
import { ExcelService } from 'app/services/excel.service';

@Component({
  selector: 'app-partner-center-list',
  templateUrl: './partner-center-list.component.html',
  styleUrls: ['./partner-center-list.component.css']
})
export class PartnerCenterListComponent implements OnInit {
  @ViewChild('TABLE', { static: true }) table: ElementRef;
  // showMainContent: Boolean = true;
  showMainContent;
  UserId;
  pgid;
  PartnerSchoolList;
  centercount;
  pifcenterlistcount;
  state;
  PartnerCenterList;
  city;
  loading = true;
  array = [];
  StrWhere = "";
  loaded;
  pifcenterlist;
  where = "";
  statelist = new FormControl();
  citylist = new FormControl();
  searchstate; selectfiyear
  searchcity; academicyear; pgname;
  searchstatus; PartnerSchoolListcount;
  p: number = 1;
  constructor(private service: MasterService, private exService: ExcelService) {
    this.UserId = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selectfiyear = localStorage.getItem('key');
    this.pgname = localStorage.getItem('pgname');

    if (this.selectfiyear == "2024-2025") {
      if (this.UserId == "AD2") {
        this.service.PIFCenterList("").subscribe((data: any) => {
          this.PartnerCenterList = data;
          this.centercount = data.length;
          this.loading = false;
        })

      }
       else {
        this.service.PartnerCenterList(this.pgid, this.UserId, "where academicyear='" + this.selectfiyear + "'").subscribe((data: any) => {
          this.PartnerCenterList = data;
          this.loading = false;

        });
        this.service.PartnerCenterList(this.pgid, this.UserId, "where  academicyear='" + this.selectfiyear + "'").subscribe((data: any) => {
          this.centercount = data.length;
        });

        if (this.pgid == 'P9') {
          this.service.FillDropDown("Center", "distinct state", "state", "where pgid = 'P9' union select distinct state,state from CenterLite where pgid = 'P9'").subscribe(
            (data: any) => {
              this.state = data;
            });
          this.service.FillDropDown("Center", "distinct city", "city", "where pgid = 'P9' union select distinct city,city from CenterLite where pgid='P9'").subscribe(
            (data: any) => {
              this.city = data;
            });
        }
        else {
          this.service.FillDropDown("Center", "distinct state", "state", "inner join CenterPartner on center.centid=CenterPartner.centid where Centerpartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "'").subscribe(
            (data: any) => {
              this.state = data;
            });
          this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid where Centerpartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "'").subscribe(
            (data: any) => {
              this.city = data;
            });
        }


      }
    }
    else if (this.selectfiyear == "2017-2018" || this.selectfiyear == "2018-2019" || this.selectfiyear == "2019-2020" || this.selectfiyear == "2020-2021" || this.selectfiyear == "2021-2022" || this.selectfiyear == "2022-2023" || this.selectfiyear == "2023-2024") {
      this.service.OLDPartnerCenterList(this.pgid, this.UserId, this.selectfiyear, "and partnerid='" + this.UserId + "'").subscribe((data: any) => {
        this.PartnerCenterList = data;
        this.centercount = data.length;
        this.loading = false;
      });

      if (this.pgid == 'P9') {
        this.service.FillDropDown("Center", "distinct state", "state", "where pgid = 'P9' union select distinct state,state from CenterLite where pgid = 'P9'").subscribe(
          (data: any) => {
            this.state = data;
          });
        this.service.FillDropDown("Center", "distinct city", "city", "where pgid = 'P9' union select distinct city,city from CenterLite where pgid='P9'").subscribe(
          (data: any) => {
            this.city = data;
          });
      }
      else {
        this.service.FillDropDown("OLDPartnerCenterList", "distinct state", "state", " where partnerid='" + this.UserId + "' and pgid='" + this.pgid + "' and academicyear = '"+this.selectfiyear+"'").subscribe(
          
          (data: any) => {
            this.state = data;
          });

        this.service.FillDropDown("OLDPartnerCenterList", "distinct city", "city", "where partnerid='" + this.UserId + "' and pgid='" + this.pgid + "' and academicyear = '"+this.selectfiyear+"'").subscribe(
          (data: any) => {
            this.city = data;
          });
      }
    }
    else if (this.selectfiyear == null) {
      if (this.UserId == "AD2") {
        this.service.PIFCenterList("").subscribe((data: any) => {
          this.PartnerCenterList = data;
          this.centercount = data.length;
          this.loading = false;
        })

      } else {
        this.selectfiyear = "2024-2025";
        this.service.PartnerCenterList(this.pgid, this.UserId, "where academicyear='" + "2023-2024" + "'").subscribe((data: any) => {
          this.PartnerCenterList = data;
          this.centercount = data.length;
          this.loading = false;
        });
      }



      if (this.pgid == 'P9') {
        this.service.FillDropDown("Center", "distinct state", "state", "where pgid = 'P9' union select distinct state,state from CenterLite where pgid = 'P9'").subscribe(
          (data: any) => {
            this.state = data;
          });
        this.service.FillDropDown("Center", "distinct city", "city", "where pgid = 'P9' union select distinct city,city from CenterLite where pgid='P9'").subscribe(
          (data: any) => {
            this.city = data;
          });
      }
      else {
        this.service.FillDropDown("Center", "distinct state", "state", "inner join CenterPartner on center.centid=CenterPartner.centid where Centerpartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "'  ").subscribe(
          (data: any) => {
            this.state = data;
          });

        this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid where Centerpartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "'  ").subscribe(
          (data: any) => {
            this.city = data;
          });
      }
    }

  }


  
  ngOnInit(): void {

    if (this.selectfiyear == "2024-2025" || this.selectfiyear == null) {
      this.showMainContent = true;
    }

  }

  filterrecord(searchstate, searchcity, searchstatus) {
    this.loading = true;

    this.array = [];
    this.where = "";
    this.StrWhere = "";

    if (this.selectfiyear == null) {

      if (searchstate != null && searchstate != "" && searchstate != "undefined" && searchstate != "") {
        //this.array.push("Center.state='" + searchstate + "'");
        this.array.push("center.state in (" + this.getMultiSelectionWhere(searchstate.toString()) + ")");

      }
      if (searchcity != null && searchcity != "" && searchcity != "undefined" && searchcity != "") {
        //this.array.push("Center.city='" + searchcity + "'");
        this.array.push("center.city in (" + this.getMultiSelectionWhere(searchcity.toString()) + ")");

      }
      if (searchstatus != null && searchstatus != "" && searchstatus != "undefined" && searchstatus != "") {
        //this.array.push("Center.status='" + searchstatus + "'");
        this.array.push("center.status in (" + this.getMultiSelectionWhere(searchstatus.toString()) + ")");

      }

      if (this.array.length == 0) {
        this.where = this.StrWhere;
      }
      else if (this.array.length == 1) {
        this.StrWhere = " where " + this.array[0].toString();
      }
      else {
        this.StrWhere = " where " + this.array[0].toString();
        for (let i = 1; i < this.array.length; i++) {
          this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
        }
      }
      this.where = this.StrWhere;

      this.service.PartnerCenterList(this.pgid, this.UserId, this.where).subscribe((data: any) => {
        this.PartnerCenterList = data;
        this.centercount = data.length;
        this.loading = false;
      });
    }
    else if (this.selectfiyear == "2024-2025") {

      if (searchstate != null && searchstate != "" && searchstate != "undefined" && searchstate != "") {
        //this.array.push("Center.state='" + searchstate + "'");
        this.array.push("center.state in (" + this.getMultiSelectionWhere(searchstate.toString()) + ")");

      }
      if (searchcity != null && searchcity != "" && searchcity != "undefined" && searchcity != "") {
        //this.array.push("Center.city='" + searchcity + "'");
        this.array.push("center.city in (" + this.getMultiSelectionWhere(searchcity.toString()) + ")");

      }
      if (searchstatus != null && searchstatus != "" && searchstatus != "undefined" && searchstatus != "") {
        //this.array.push("Center.status='" + searchstatus + "'");
        this.array.push("center.status in (" + this.getMultiSelectionWhere(searchstatus.toString()) + ")");

      }

      if (this.array.length == 0) {
        this.where = this.StrWhere;
      }
      else if (this.array.length == 1) {
        this.StrWhere = " where " + this.array[0].toString();
      }
      else {
        this.StrWhere = " where " + this.array[0].toString();
        for (let i = 1; i < this.array.length; i++) {
          this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
        }

      }
      this.where = this.StrWhere;

      this.service.PartnerCenterList(this.pgid, this.UserId, this.where).subscribe((data: any) => {
        this.PartnerCenterList = data;
        this.centercount = data.length;
        this.loading = false;

      });

    }

    else if (this.selectfiyear == "2017-2018" || this.selectfiyear == "2018-2019" || this.selectfiyear == "2019-2020" || this.selectfiyear == "2020-2021" || this.selectfiyear == "2021-2022" || this.selectfiyear == "2022-2023" || this.selectfiyear == "2023-2024") {

      if (searchstate != null && searchstate != "" && searchstate != "undefined" && searchstate != "") {
        //this.array.push("Center.state='" + searchstate + "'");
        this.array.push("state in (" + this.getMultiSelectionWhere(searchstate.toString()) + ")");

      }
      if (searchcity != null && searchcity != "" && searchcity != "undefined" && searchcity != "") {
        //this.array.push("Center.city='" + searchcity + "'");
        this.array.push("city in (" + this.getMultiSelectionWhere(searchcity.toString()) + ")");

      }
      if (searchstatus != null && searchstatus != "" && searchstatus != "undefined" && searchstatus != "") {
        //this.array.push("Center.status='" + searchstatus + "'");
        this.array.push("center.status in (" + this.getMultiSelectionWhere(searchstatus.toString()) + ")");

      }

      if (this.array.length == 0) {
        this.where = this.StrWhere;
      }
      else if (this.array.length == 1) {
        this.StrWhere = " and " + this.array[0].toString();
      }
      else {
        this.StrWhere = " and " + this.array[0].toString();
        for (let i = 1; i < this.array.length; i++) {
          this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
        }

      }
      this.where = this.StrWhere;
      
      this.service.OLDPartnerCenterList(this.pgid, this.UserId, this.selectfiyear, this.where).subscribe((data: any) => {
        this.PartnerCenterList = data;
        this.centercount = data.length;
        this.loading = false;

      });

    }

    else if (this.selectfiyear == '2021-2022' && this.UserId == 'AD2') {
      if (searchstate != null && searchstate != "" && searchstate != "undefined" && searchstate != "") {
        //this.array.push("Center.state='" + searchstate + "'");
        this.array.push("center.state in (" + this.getMultiSelectionWhere(searchstate.toString()) + ")");

      }
      if (searchcity != null && searchcity != "" && searchcity != "undefined" && searchcity != "") {
        //this.array.push("Center.city='" + searchcity + "'");
        this.array.push("center.city in (" + this.getMultiSelectionWhere(searchcity.toString()) + ")");

      }
      if (searchstatus != null && searchstatus != "" && searchstatus != "undefined" && searchstatus != "") {
        //this.array.push("Center.status='" + searchstatus + "'");
        this.array.push("center.status in (" + this.getMultiSelectionWhere(searchstatus.toString()) + ")");

      }

      if (this.array.length == 0) {
        this.where = this.StrWhere;
      }
      else if (this.array.length == 1) {
        this.StrWhere = " where " + this.array[0].toString();
      }
      else {
        this.StrWhere = " where " + this.array[0].toString();
        for (let i = 1; i < this.array.length; i++) {
          this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
        }

      }
      this.where = this.StrWhere;

      this.service.PIFCenterList("").subscribe((data: any) => {
        this.PartnerCenterList = data;
        this.centercount = data.length;
        this.loading = false;
      })

    }

  }

  selectstate(st) {
    if (st._selected) {
      this.statelist.setValue([]);
    }
  }

  selectcity(ct) {
    if (ct._selected) {
      this.citylist.setValue([]);
    }
  }

  ExportReport(searchstate, searchcity, searchstatus) {
    this.loading = true;
    if (this.selectfiyear == "2024-2025") {
      this.service.PartnerCenterList(this.pgid, this.UserId, this.where).subscribe((data: any) => {
        this.loading = false;
        const new_list = data.map(function (obj) {
          return {
            'Srno': 0,
            'Center/School Name': obj.centname == null ? '' : obj.centname,
            'State': obj.state == null ? '' : obj.state,
            'City': obj.city == null ? '' : obj.city,
            'Address': obj.address == null ? '' : obj.address,
            'Principal Name': obj.principalname == null ? '' : obj.principalname,
            'Contact': obj.principalcontact == null ? '' : obj.principalcontact,
          };
        });
        for (let i = 0; i < new_list.length; i++) {
          new_list[i].Srno = i + 1;
        }
        this.exService.exportAsExcelFile(new_list, 'Partner center List');
      });

    }
    else if (this.selectfiyear == "2017-2018" || this.selectfiyear == "2018-2019" || this.selectfiyear == "2019-2020" || this.selectfiyear == "2020-2021" || this.selectfiyear == "2021-2022" || this.selectfiyear == "2022-2023" || this.selectfiyear == "2023-2024") {
      this.service.OLDPartnerCenterList(this.pgid, this.UserId, this.selectfiyear, this.where).subscribe((data: any) => {
        this.loading = false;
        const new_list = data.map(function (obj) {
          return {
            'Srno': 0,
            'Center/School Name': obj.centname == null ? '' : obj.centname,
            'State': obj.state == null ? '' : obj.state,
            'City': obj.city == null ? '' : obj.city,
            'Address': obj.address == null ? '' : obj.address,
            'Principal Name': obj.principalname == null ? '' : obj.principalname,
            'Contact': obj.principalcontact == null ? '' : obj.principalcontact,
          };
        });
        for (let i = 0; i < new_list.length; i++) {
          new_list[i].Srno = i + 1;
        }
        this.exService.exportAsExcelFile(new_list, 'Partner center List');
      });
    }
  }

  getMultiSelectionWhere(where) {

    var arr = where.split(',');
    var currentWhere = '';

    for (var i = 0; i < arr.length; i++) {
      currentWhere += "'" + arr[i] + "'";
      if (i + 1 != arr.length)
        currentWhere += ",";
    }
    return currentWhere;
  }

  onSelectState(stname) {
    this.searchcity = '';
    if (this.pgid == 'P9') {
      // this.service.FillDropDown("Center", "distinct city", "city", "where state = '" + stname + "' and pgid = 'P9' union select distinct city,city from CenterLite where pgid='P9'").subscribe(
      //   (data: any) => {
      //     this.city = data;
      //   });
      this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid where centerpartner.partnerid='" + this.UserId + "' and center.state in (" + this.getMultiSelectionWhere(stname.toString()) + ") union select distinct city,city from CenterLite inner join CenterPartnerlite on CenterLite.centid=CenterPartnerlite.centid where pgid='P9' and centerpartnerlite.partnerid='" + this.UserId + "' and state in (" + this.getMultiSelectionWhere(stname.toString()) + ")").subscribe(
        (data: any) => {
          this.city = data;
        });
    }
    else {
      this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid where centerpartner.partnerid='" + this.UserId + "' and center.state in (" + this.getMultiSelectionWhere(stname.toString()) + ")").subscribe(
        (data: any) => {
          this.city = data;
        });
    }
  }

  ShowHideButton() {
    this.showMainContent = this.showMainContent ? false : true;
  }
}