import { Component, OnInit , EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Reportlist } from 'app/ClassFiles/reportlist';
import { MasterService } from 'app/services/master.service';

@Component({
  selector: 'app-addreport',
  templateUrl: './addreport.component.html',
  styleUrls: ['./addreport.component.css']
})
export class AddreportComponent implements OnInit {
  public addreportform: FormGroup;
  partner;
  program;
  userid;
  imageUrl: string;
  url: string;
  fileToUpload: File = null;
  loading = false;
  submited =  new EventEmitter<string>();
  constructor(private router: Router, private fb: FormBuilder, private service: MasterService) {
   this.userid = localStorage.getItem('UserId');
    this.service.FillDropDown("PartnerProgram","distinct partnername","Partner.partnerid","inner join AcademicyearPartner on AcademicyearPartner.partnerid=PartnerProgram.userid  inner join PartnerProfile on PartnerProgram.userid=PartnerProfile.userid inner join partner on PartnerProfile.superpartnerid = partner.partnerid where academicyear in('2023-2024','2024-2025') and partnerprofile.userid='"+ this.userid +"'").subscribe((data: any) => {
      this.partner = data;
    });

    this.service.FillDropDown("PartnerProgram","distinct program.pgname","center.pgid","inner join AcademicyearPartner on AcademicyearPartner.partnerid=PartnerProgram.userid  inner join PartnerProfile on PartnerProgram.userid=PartnerProfile.userid inner join partner on PartnerProfile.superpartnerid = partner.partnerid inner join CenterPartner on CenterPartner.partnerid=partner.partnerid inner join center on CenterPartner.centid=center.centid and center.status=0 and CenterPartner.status=0 inner join Program on center.pgid=Program.pgid and partnerprofile.userid='"+ this.userid +"'").subscribe((data: any) => {
      this.program = data;
    });
   }

   onselectpartner(partnerdpd) {
    if (partnerdpd == '' || partnerdpd.length == 0 || partnerdpd[0] == undefined)  {
      this.service.FillDropDown("PartnerProgram","distinct program.pgname","center.pgid","inner join AcademicyearPartner on AcademicyearPartner.partnerid=PartnerProgram.userid  inner join PartnerProfile on PartnerProgram.userid=PartnerProfile.userid inner join partner on PartnerProfile.superpartnerid = partner.partnerid inner join CenterPartner on CenterPartner.partnerid=partner.partnerid inner join center on CenterPartner.centid=center.centid and center.status=0 and CenterPartner.status=0 inner join Program on center.pgid=Program.pgid and partnerprofile.userid='"+ this.userid +"'").subscribe((data: any) => {
      this.program = data;
    });
      } else {
        this.service.FillDropDown("PartnerProgram","distinct program.pgname","PartnerProgram.pgid","inner join Program on PartnerProgram.pgid=Program.pgid where PartnerProgram.userid='" + partnerdpd + "'")
        .subscribe((data: any) => {
          this.program = data;
     
        })
        }
  }

  ngOnInit(): void {
    this.addreportform = new FormGroup({
      partname : new FormControl('', Validators.required),
      progname :  new FormControl('', Validators.required),
      acyear :  new FormControl('', Validators.required),
      choosefile :  new FormControl('', Validators.required),
    });
  
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.addreportform.controls[controlName].hasError(errorName);
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    //Show image preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    
    reader.readAsDataURL(this.fileToUpload);
  }

Addreport() {
  this.loading = true;
  const addreport: Reportlist = new Reportlist();
  var partnerid = this.addreportform.value.partname;
  var pgid = this.addreportform.value.progname;
  var academicyear =this.addreportform.value.acyear;
  var createdby = this.userid;

  this.service.PartnerPDFReport(partnerid, pgid, academicyear,createdby,this.fileToUpload).subscribe((res) => {
    if (res == 'Success') {
      this.submited.emit(res.toString());
      localStorage.setItem('reportid', res.toString());
      alert("Report Added Succesfully");
      this.router.navigate(['/ReportList'])
    }else if (res == 'Exist') {
      alert("Report Existed");
      this.router.navigate(['/ReportList'])
    }
    else if (res == 'Failure') {
      alert("File Size Should be less then 5MB");
      this.router.navigate(['/ReportList'])
    }
this.loading = false;
  
  });
}
}
