import { Component, OnInit ,EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MasterService } from 'app/services/master.service';

@Component({
  selector: 'app-partnerwisephotolist',
  templateUrl: './partnerwisephotolist.component.html',
  styleUrls: ['./partnerwisephotolist.component.css']
})
export class PartnerwisephotolistComponent implements OnInit {
  partnerlist = new FormControl();
  searchpartner;
  partner;
  where = '';
  StrWhere = '';
  array = [];
  loading = true;
  p: number = 1;
  photocount;
  photolist;
  submited = new EventEmitter<string>();
  userid: string;
  constructor(private service: MasterService) {
   this.userid=localStorage.getItem('UserId');
    this.pageload();

    this.service.GetPartnerPhotogalleryList("where partnerprofile.userid='"+ this.userid +"'").subscribe((data:any) => {
      this.photolist = data;
      this.photocount = data.length;
      this.loading = false;
    });

  }

  pageload(){
    // this.service.FillDropDown("PartnerProgram","distinct partnername","userid","inner join Partner on PartnerProgram.userid=Partner.partnerid inner join AcademicyearPartner on AcademicyearPartner.partnerid=PartnerProgram.userid where academicyear='2020-2021'").subscribe((data: any) => {
    //   this.partner = data;
    // });
    this.service.FillDropDown("PartnerProgram","distinct partnername","Partner.partnerid","inner join AcademicyearPartner on AcademicyearPartner.partnerid=PartnerProgram.userid  inner join PartnerProfile on PartnerProgram.userid=PartnerProfile.userid inner join partner on PartnerProfile.superpartnerid = partner.partnerid where academicyear in('2023-2024','2024-2025') and partnerprofile.userid='"+ this.userid +"'").subscribe((data: any) => {
      this.partner = data;
    });
    
  }

  ngOnInit(): void {
  }

  getMultiSelectionWhere(where) {
    let arr = where.split(',');
    let currentWhere = '';
    for (let i = 0; i < arr.length; i++) {
      currentWhere += '\'' + arr[i] + '\'';
      if (i + 1 != arr.length) {
        currentWhere += ',';
      }
    }
    return currentWhere;
  }

  filterrecord(searchpartner) {
    this.loading = true;
    this.array = [];
    this.StrWhere = '';
    if(searchpartner!= null  &&  searchpartner!='' &&  searchpartner!='undefined' && searchpartner!='select' ){
      this.array.push("partner.partnerid in(" + this.getMultiSelectionWhere(searchpartner.toString()) + ") and PartnerProfile.userid= '"+ this.userid +"'");
    }
    
   if (this.array.length == 0) {
    this.StrWhere= " where PartnerProfile.userid= '"+ this.userid +"'";
  } else if (this.array.length == 1) {
     this.StrWhere = " where PartnerProfile.userid= '"+ this.userid +"' and " + this.array[0].toString();
   } else {
     this.StrWhere = ' where ' + this.array[0].toString();
     for (let i = 1; i < this.array.length; i++) {
       this.StrWhere = this.StrWhere + ' and ' + this.array[i].toString();
     }

   }
   this.where = this.StrWhere;
   this.service.GetPartnerPhotogalleryList(this.where).subscribe((data:any) => {
    this.photolist = data;
    this.photocount = data.length;
    this.loading = false;
    console.log(this.photolist)
  });


  }

  Delete(gid) {
    this.service.RemovePhotogallery(gid).subscribe((res) => {
      this.submited.emit(res.toString());
      this.service.GetPartnerPhotogalleryList("where partnerprofile.userid='"+ this.userid +"'").subscribe((data:any) => {
        this.photolist = data;
        this.photocount = data.length;
        console.log(this.photolist)
      });
      error => {
        console.log(error);
      }
    });
  }


  selectpartner(pt){
    if (pt._selected) {
      this.partnerlist.setValue([]);
    }
  }

}
