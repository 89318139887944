import { Component, OnInit , EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MasterService } from 'app/services/master.service';
// import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reportlist',
  templateUrl: './reportlist.component.html',
  styleUrls: ['./reportlist.component.css']
})
export class ReportlistComponent implements OnInit {
  searchprogram;
  searchpartner;
  searchacademic;
  academiclist = new FormControl();
  programlist = new FormControl();
  partnerlist = new FormControl();
  reportlist;
  partner;
  program;
  where = '';
  StrWhere = '';
  array = [];
  loading = true;
  reportcount;
  p: number = 1;
  pdfid;
  Viewpdf;
  submited = new EventEmitter<string>();
  userid: string;
  constructor(private service: MasterService) {
    this.userid=localStorage.getItem('UserId');
    this.service.PartnerPDFList("where partnerprofile.userid='"+ this.userid +"'").subscribe((data: any) => {
      this.reportlist = data;
      this.pdfid = data.pdfid;
      this.reportcount = data.length;
      this.loading = false;
    });

   
    this.service.FillDropDown("PartnerProgram","distinct partnername","Partner.partnerid","inner join AcademicyearPartner on AcademicyearPartner.partnerid=PartnerProgram.userid  inner join PartnerProfile on PartnerProgram.userid=PartnerProfile.userid inner join partner on PartnerProfile.superpartnerid = partner.partnerid where academicyear in('2023-2024','2024-2025') and partnerprofile.userid='"+ this.userid +"'").subscribe((data: any) => {
      this.partner = data;
    });

    this.service.FillDropDown("PartnerProgram","distinct program.pgname","center.pgid","inner join AcademicyearPartner on AcademicyearPartner.partnerid=PartnerProgram.userid  inner join PartnerProfile on PartnerProgram.userid=PartnerProfile.userid inner join partner on PartnerProfile.superpartnerid = partner.partnerid inner join CenterPartner on CenterPartner.partnerid=partner.partnerid inner join center on CenterPartner.centid=center.centid and center.status=0 and CenterPartner.status=0 inner join Program on center.pgid=Program.pgid and partnerprofile.userid='"+ this.userid +"'").subscribe((data: any) => {
      this.program = data;
    })
  }

  onselectpartner(partnerdpd) {
    if (partnerdpd == '' || partnerdpd.length == 0 || partnerdpd[0] == undefined)  {
      // this.service.FillDropDown("PartnerProgram","distinct program.pgname","PartnerProgram.pgid","inner join Program on PartnerProgram.pgid=Program.pgid").subscribe((data: any) => {
      // this.program = data;
      this.service.FillDropDown("PartnerProgram","distinct program.pgname","center.pgid","inner join AcademicyearPartner on AcademicyearPartner.partnerid=PartnerProgram.userid  inner join PartnerProfile on PartnerProgram.userid=PartnerProfile.userid inner join partner on PartnerProfile.superpartnerid = partner.partnerid inner join CenterPartner on CenterPartner.partnerid=partner.partnerid inner join center on CenterPartner.centid=center.centid and center.status=0 and CenterPartner.status=0 inner join Program on center.pgid=Program.pgid and partnerprofile.userid='"+ this.userid +"'").subscribe((data: any) => {
        this.program = data;
    });
      } else {
        this.service.FillDropDown("PartnerProgram","distinct program.pgname","PartnerProgram.pgid","inner join Program on PartnerProgram.pgid=Program.pgid where PartnerProgram.userid in (" + this.getMultiSelectionWhere(partnerdpd.toString()) + ")")
        .subscribe((data: any) => {
          this.program = data;
        })
        }
  }

  viewpdf(value){
    this.service.GetReportPDF(value).subscribe((data: any) =>
    {
      this.Viewpdf = data;
    });

  }

   getMultiSelectionWhere(where) {
    let arr = where.split(',');
    let currentWhere = '';

    for (let i = 0; i < arr.length; i++) {
      currentWhere += '\'' + arr[i] + '\'';
      if (i + 1 != arr.length) {
        currentWhere += ',';
      }
    }
    return currentWhere;
  }

  filterrecord(searchpartner, searchprogram, searchacademic) {
    this.loading = true;
    this.array = [];
    this.StrWhere = '';
    if(searchpartner!= null  &&  searchpartner!='' &&  searchpartner!='undefined' && searchpartner!='select' ){
      this.array.push('PartnerPDF.partnerid in(' + this.getMultiSelectionWhere(searchpartner.toString()) + ')');
    }
    if(searchprogram!= null  &&  searchprogram!='' &&  searchprogram!='undefined' && searchprogram!='select' ){
     this.array.push('PartnerPDF.pgid in(' + this.getMultiSelectionWhere(searchprogram.toString()) + ')');
   }
   if(searchacademic!= null  &&  searchacademic!='' &&  searchacademic!='undefined' && searchacademic!='select' ){
    this.array.push('PartnerPDF.academicyear in(' + this.getMultiSelectionWhere(searchacademic.toString()) + ')');
  }


   if (this.array.length == 0) {
    this.StrWhere= " where PartnerProfile.userid= '"+ this.userid +"'";
  } else if (this.array.length == 1) {
     this.StrWhere = " where PartnerProfile.userid= '"+ this.userid +"' and " + this.array[0].toString();
   } else {
     this.StrWhere = ' where ' + this.array[0].toString();
     for (let i = 1; i < this.array.length; i++) {
       this.StrWhere = this.StrWhere + ' and ' + this.array[i].toString();
     }

   }
   this.where = this.StrWhere;

   this.service.PartnerPDFList(this.where).subscribe((data: any) => {
    this.reportlist = data;
    this.pdfid = data.pdfid;
    this.reportcount = data.length;
    this.loading = false;
  });

  }

  Delete(rpdfid) {
    this.service.RemoveReportPDF(rpdfid).subscribe((res) => {
      this.submited.emit(res.toString());
      this.service.PartnerPDFList(this.where).subscribe((data: any) => {
        this.reportlist = data;
        this.pdfid = data.pdfid;
        this.reportcount = data.length;
         this.loading = false;
      });
      error => {
        console.log(error);
      }
    });
  }

  ngOnInit(): void {
  }

  selectpartner(pt){
    if (pt._selected) {
      this.partnerlist.setValue([]);
    }
  }

  selectprogram(pro){
    if (pro._selected) {
      this.programlist.setValue([]);
    }
  }

  selectacademic(acd){
    if (acd._selected) {
      this.academiclist.setValue([]);
    }
  }
}
