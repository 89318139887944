import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'app/ClassFiles/user';
import { MasterService } from 'app/services/master.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  message: string;
  loading = false;
  dev: any;
  userid: any;

  constructor(private router: Router, private service: MasterService,private route: ActivatedRoute) { 
    
  }

  ngOnInit() { 
    
  }

  Loginuser(values) {
    this.loading = true;
    let user = new User();
    user.UserName = values.UserName;
    user.Password = values.Password;
    this.service.login(user).subscribe(resp => {
      
      if (resp[0].Result === "Faliure") {
        this.loading = false;
        this.message = "Please check UserName and password";
      }
      else if (resp[0].Result === "User Inactive") {
        this.message = "User Inactive";
      }
      else {
          localStorage.setItem('UserId', resp[0].UserId);
          // localStorage.setItem('UserName', JSON.stringify(resp[0].UserName));
          localStorage.setItem('UserName', resp[0].UserName);
          localStorage.setItem('Userrole', resp[0].Userrole);
          localStorage.setItem('FullName', resp[0].FullName);
          localStorage.setItem('pgid', resp[0].pgid);
          localStorage.setItem('pgname', resp[0].pgname);
          localStorage.setItem('key', resp[0].academicyear);
          localStorage.setItem('isOtpVerify', 'false');
          // const navigationExtras = {
          //   state: { dev: true }
          // };
          this.router.navigate(['/OTPPage']);
        
        }
      
      
    });
}
}
