import { Injectable } from '@angular/core';
import { CanActivate,Router } from '@angular/router';

@Injectable()

export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate()
  {
      var UserId = localStorage.getItem("UserId");
        if(UserId !=undefined)  {
          return true;
        }      
           
        else
        {
          this.router.navigate(['/Login']);
            return false;
        }
  }
  
}
